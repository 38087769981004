import React from "react"
import Back from "../common/Back"
import Heading from "../common/Heading"
import img from "../images/about.jpg"
import bijan from "../../components/images/bijan.jpg"
import ranjan from "../../components/images/ranjan.jpg"
import sanjay from "../../components/images/download.jpg"
import Aboutvideo from "../../Video/homeVideo3.mp4"
// import 'bootstrap/dist/css/bootstrap.min.css';
import "./about.css"

const About = () => {

  return (
    <>
      <div className='about'>
        {/* <Back name='About Us' cover={img} /> */}
        <video  title="About Us" className="video" autoPlay loop muted>
              <source
                src={Aboutvideo}
                type="video/mp4"
                className="img-fluid"
                allowFullScreen
              />
            </video>
        <div className='container flex mtop'>
          <div className=' row'>
            <Heading title='About Us' />
            <p>Our company specializes in the art of crafting personalized digital solutions tailored to a diverse range of industries. Within our team, you'll find a group of seasoned experts well-versed in the latest cutting-edge technologies, including Node.js, React, .NET, Oracle, MySQL, PHP, Postgres, Java, Spring Boot, and Bootstrap. With this extensive knowledge and experience, we possess the capability to seamlessly transform your offline operations into streamlined online systems, ultimately boosting your productivity and enhancing product performance.</p>
            <p>You can confidently rely on our dedicated experts to ensure that your project not only meets but exceeds your expectations.</p>
            <p>Furthermore, our team boasts Subject Matter Experts who specialize in domains such as EHS, Sustainability, ESG, BRSR, and other facets of Environment and Sustainability, encompassing Governance and HR. Through tailor-made solutions, we stand ready to help you navigate the unique challenges and opportunities your industry presents while ensuring strict adherence to relevant regulations and standards.</p>
            <p>At the heart of our company's philosophy lies the belief that technology is a potent instrument for positive change. We are steadfast in our commitment to constructing digital solutions that are not only efficient and effective but also sustainable and socially responsible. With our seasoned and knowledgeable team at the helm, rest assured that your project is in the finest hands and will be delivered to your complete satisfaction.</p>
          </div>
        </div>
      </div>
   
      <div className="cardSection">
        <div class="row">
          <div class="col">
            <div class="teamcard">
              <img src={bijan} alt="Jane" className="profile" />
              <div class="container3">
                <h6>BIJAN KUMAR MISHRA</h6>
                <p class="title">Managing Director</p>
                <div className="cardbtn">
                  <div class="wrapper">
                    <a href="#demo-modal1">Read More</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="teamcard">
              <img src={ranjan} alt="Mike" className="profile" />
              <div class="container3">
                <h6>RANJAN KUMAR MISHRA</h6>
                <p class="title"> Director</p>
                <div className="cardbtn">
                  <div class="wrapper">
                    <a href="#demo-modal2
                    ">Read More</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="teamcard">
              <img src={sanjay} alt="John" className="profile" />
              <div class="container3">
                <h6>SANJAY SALIL</h6>
                <p class="title">Director</p>
                <div className="cardbtn">
                  <div class="wrapper">
                    <a href="#demo-modal3">Read More</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="demo-modal1" class="modalC">
        <div class="modal__content">
          <h3>BIJAN KUMAR MISHRA</h3>
          <img src={bijan} alt="Jane" className="mdlprofile" />
          <p style={{ "textAlign": "justify", "marginTop": "10px" }}>
            A adroit white collar with enormous experience of 30 years in the areas like Environmental & Social specialist with extensive experience in Power Plants (Thermal/Hydro/Gas/ Solar/Wind), Infrastructures, Mining, Cement including Waste management, treatment and remediation, Environmental Policies, Compliance; Gap Analysis etc. He succored as Manager- Environment in Ambuja Cements Ltd for 13 years and Senior Vice President- EHS in Reliance Power Ltd for 9 long years. He also served in various other prestigious organizations like Welspun Energy Ltd, AD Hydro Power Project, Infrastructure Ltd, and Reliance Infrastructures Ltd at aristocratic level. He is fully conversant with Environmental & Social performance standards such as IFC Performance Standards and EHS Guidelines, Equator Principles, ESMF requirements, ADB Environment and Safeguards Policy. He was engaged with the environmental & Social aspects for development of Power Station (Thermal/ Gas/ Solar/ Wind / Hydro), LNG T erminals, Cement Manufacturing Units, Limestone and Coal Mining etc. with various industries in India and Bangladesh. His affair include: Permits & Licenses for Environment & Forest Matter from MoEF&CC, State PCB, CGWA etc, Management of the Compliance of IFC EHS&S Standards & EQP guidelines, etc. He is Competent in flourishing Emergency Responses Plans and Environment & Forests related Plans & Procedures as per IFC/ Equator’s Principle.
          </p>
          <a href="#" class="modal__close">&times;</a>
        </div>
      </div>
      <div id="demo-modal2" class="modalC">
        <div class="modal__content">
          <h3>RANJAN KUMAR MISHRA</h3>
          <img src={ranjan} alt="Mike" className="mdlprofile" />
          <p style={{ "textAlign": "justify", "marginTop": "10px" }}>
            Ranjan Kumar Mishra Armed with a management degree,Ranjan a thoroughmanagement professional with more than 12 years in the corporate world in various leadership positions with several large corporate in India. He is committed to key issues of quality, integrity and professionalism. Ranjan is committed to setting a clear and strong tone from the top around the key issues of quality, integrity and professionalism Based in New Delhi, Ranjan also serves as senior advisory partner for many of our largest clients. He encourages dialogue with keystakeholders across our several clients regarding the many changes facing the Indian Business Scenario, including overall movement towards greater convergence and governance.
          </p>
          <a href="#" class="modal__close">&times;</a>
        </div>
      </div>
      <div id="demo-modal3" class="modalC">
        <div class="modal__content">
          <h3>SANJAY SALIL</h3>
          <img src={sanjay} alt="John" className="mdlprofile" />

          <p style={{ "textAlign": "justify", "marginTop": "10px" }}>
            A media entrepreneur with an experience in consulting some of the world’s leading broadcasters and media companies on technology, content and business strategy. As a founder of MediaGuru, Sanjay has led the company through a decade of success, in a competitive, complex global market and driving significant growth over the years. Sanjay has overseen the launch of broadcast stations and media ventures across Asia, Middle East and Africa.
            Sanjay has shared his experiences and knowledge at global forums like AIB, ABU Digital Broadcasting Summit, Asia Media Summit, CABSAT, FIAT IFTA World Conference, Saudi Media Forum, Guardian Activate Summit, Big Tent Summit, and IBC conference. He has been a part of the jury for the coveted International EMMY awards and ABU Broadcast Engineering Excellence awards.
            Sanjay’s insights and interviews have been featured in global publications like Business Standard, CNN, Washington Post, Mint – Wall Street Journal among others. He was recently listed among successful entrepreneurs of India in the book “Small Big Bang” written by IIM, India’s premier management institute.
          </p>
          <a href="#" class="modal__close">&times;</a>
        </div>
      </div>
    </>
  )
}

export default About
